<template>
  <div class="container text-center mt-5">
    <h1>Connect to Xero</h1>
    <button class="btn btn-primary" @click="connectToXero">Connect to Xero</button>

    <div v-if="companies.length > 0">
      <h2>Select Company</h2>
      <select v-model="selectedCompany" class="form-select">
        <option v-for="company in companies" :key="company.companyId" :value="company.companyId">
          {{ company.name }}
        </option>
      </select>
      <button class="btn btn-success mt-3" @click="getLedgerData">Get Ledger Data</button>
    </div>

    <div v-if="ledgerData">
      <h2>Ledger Data</h2>
      <pre>{{ ledgerData }}</pre>
      <button class="btn btn-info mt-3" @click="downloadLedgerData">Download Ledger Data</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      companies: [],
      selectedCompany: null,
      ledgerData: null,
      authCode: null,
    };
  },
  methods: {

    connectToXero() {
      /*const clientId = '358C6B089BDD4208B710D50AC0449993'; // Replace with your Client ID
      const redirectUri = 'https://api.scrubbed.net/auth/xero/callback'; // Replace with your redirect URI
      const scope = 'openid profile email accounting.transactions';
      function generateState() {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
      }
      const state = generateState(); // Generate a unique state string

      //const authUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scope}&state=${state}`;*/
      window.location.href = 'https://api.scrubbed.net/auth/xero/'; // Redirect to Xero for authorization
    },


    async exchangeCodeForAccessToken() {

      try {

          const urlParams = new URLSearchParams(window.location.search);
          const accessToken = urlParams.get('access_token');

          // Check if the access token is available
          if (!accessToken) {
            throw new Error('Access token not found in the URL');
          }

        this.getCompanies(accessToken); // Fetch companies after obtaining the access token
      } catch (error) {
        console.error('Error exchanging code for access token:', error);
      }
    },


    async getCompanies(accessToken) {
      
      const response = await axios.get('https://api.xero.com/connections', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.companies = response.data; // Store the companies retrieved from Xero
    },


    async getLedgerData() {

      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('access_token');

      // Replace with the actual API endpoint to get ledger data
      const ledgerEndpoint = `https://api.xero.com/api.xro/2.0/Ledgers/${this.selectedCompany}`;

      try {
        const response = await axios.get(ledgerEndpoint, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        this.ledgerData = response.data; // Store the ledger data
      } catch (error) {
        console.error('Error retrieving ledger data:', error);
      }
    },


    downloadLedgerData() {
      const dataStr = JSON.stringify(this.ledgerData, null, 2);
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'ledgerData.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },


  mounted() {
    // Check for the authorization code in the URL after redirect
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    if (accessToken) {
      this.exchangeCodeForAccessToken();
      console.log('cors error');
    }
  },
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
</style>
